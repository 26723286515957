<script setup lang="ts">
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { isEmpty } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useIntersectionObserver, useHotelCheapest, useHotelPath, ref, until, useDevice } from '#imports';

import 'swiper/css';
import 'swiper/css/navigation';

const modules = [Navigation];

const { country, currency } = storeToRefs(useConfigStore());
const { locale } = useI18n();
const { getHotelPath } = useHotelPath();
const {
  activeCityRef,
  changeActiveCity,
  getHotelSearchPath,
  trackCheapHotel,
  trackCheapHotelNav,
  trackCheapHotelSeeMore,
} = useHotelCheapest();

const { isCrawler } = useDevice();

const {
  data: cities,
  error: cityError,
  pending: cityPending,
  execute: fetchCheapestHotelCities,
} = await useAsyncData(
  () =>
    useHotelService().getHotelRecommendationCities({
      country: country.value!,
      lang: locale.value,
    }),
  {
    immediate: isCrawler,
    transform: (data) => data.result.cities,
  },
);

const activeCity = computed(() => activeCityRef.value || (cities.value && cities.value[0]!));

const { data: hotels, pending: hotelPending } = await useAsyncData(
  () =>
    useHotelService().getCheapestHotel({
      city: activeCity.value.id,
      currency: currency.value!,
      lang: locale.value,
    }),
  {
    immediate: isCrawler,
    watch: [currency, activeCity, cities],
    transform: (data) => data.result.hotels,
  },
);

const hotelCheapestRef = ref<HTMLElement | null>(null);
const initialState = ref(!isCrawler);

const { stop } = useIntersectionObserver(
  hotelCheapestRef,
  // @ts-expect-error
  async ([{ isIntersecting }]) => {
    if (isIntersecting && !isCrawler) {
      fetchCheapestHotelCities();

      await until(cityPending).toBe(false);

      initialState.value = false;

      stop();
    }
  },
);
</script>

<template>
  <div ref="hotelCheapestRef">
    <h2 class="text-extra font-bold mb-20">
      {{ $t('hotel.smartsavinghotel') }}
    </h2>

    <HotelRecommendationCityPlaceholder
      v-if="cityPending || initialState"
      class="mb-20"
    />

    <div
      v-if="!cityError && !cityPending && !isEmpty(cities)"
      class="flex gap-x-15 mb-20"
    >
      <div
        v-for="(city, index) in cities"
        :key="index"
        :class="{ '!bg-primary !border-white': city.id === activeCity.id }"
        class="py-10 px-20 border-[1px] rounded border-solid border-gray-light bg-whiter cursor-pointer hover:bg-primary hover:border-white hover:!text-white"
        data-testid="hotel-filter-button"
        @click="changeActiveCity(city)"
      >
        <p :class="{ '!text-white font-bold': city.id === activeCity.id }">
          {{ city.name }}
        </p>
      </div>
    </div>

    <HotelRecommendationPlaceholder v-if="hotelPending" />

    <div
      v-else-if="!isEmpty(hotels)"
      class="w-full relative"
    >
      <Swiper
        :slides-per-view="4"
        :space-between="10"
        :lazy="!isCrawler"
        loop
        :navigation="{
          nextEl: '.hotel-cheapest-button-next',
          prevEl: '.hotel-cheapest-button-prev',
        }"
        :modules="modules"
        class="swiper w-full rounded"
        @touch-end="trackCheapHotelNav('slide', activeCity.name)"
      >
        <SwiperSlide
          v-for="(hotel, index) in hotels"
          :key="index"
          class="!w-[267px] mr-10 last:mr-0"
          @click="trackCheapHotel(hotel, activeCity)"
        >
          <NuxtLink
            :to="
              getHotelPath(hotel, {
                ci: hotel.checkInDate,
                co: hotel.checkOutDate,
                ro: '1',
                ad: '1',
              })
            "
            external
            no-rel
          >
            <div class="w-full relative shadow-md hover:cursor-pointer h-[235px]">
              <NuxtImg
                :src="hotel.photo"
                :alt="hotel.name"
                format="webp"
                height="235"
                width="267"
                class="rounded h-full w-full aspect-square object-cover"
                data-testid="hotel-recommended-card"
                :loading="index > 3 ? 'lazy' : 'eager'"
                fit="scale-down"
                sizes="lg:50vw"
                quality="50"
              />

              <div class="absolute inset-0 top-20 bg-gradient-to-t from-[#000000] to-[#69696900] rounded">
                <div class="absolute left-20 bottom-20 flex flex-col gap-y-5 text-white rounded">
                  <div class="flex items-end flex-wrap">
                    <p class="mr-5">
                      {{ $t('search.from') }}
                    </p>
                    <p class="font-bold text-medium">
                      {{ $c(hotel.price, hotel.currency) }}
                    </p>
                  </div>

                  <p class="font-bold">
                    {{ hotel.name }}
                  </p>

                  <SharedRating
                    :rating="hotel.star"
                    size="large"
                    wrapper-class="flex gap-x-5"
                  />
                </div>
              </div>
            </div>
          </NuxtLink>
          <div
            v-if="!isCrawler"
            class="swiper-lazy-preloader"
          ></div>
        </SwiperSlide>
      </Swiper>

      <ApzButton
        class="swiper-button-next hotel-cheapest-button-next hover:!bg-gray-lightest"
        data-testid="hotel-next-button"
        @click="trackCheapHotelNav('nav', activeCity.name)"
      />
      <ApzButton
        class="swiper-button-prev hotel-cheapest-button-prev hover:!bg-gray-lightest"
        data-testid="hotel-prev-button"
        @click="trackCheapHotelNav('nav', activeCity.name)"
      />
    </div>

    <div class="mt-40 w-full flex justify-center min-w-[55px]">
      <ApzButton
        v-if="activeCity"
        type="primary"
        variant="outline"
        tag="nuxt-link"
        :to="getHotelSearchPath(activeCity)"
        external
        no-rel
        class="w-fit !px-30 !h-[55px] text-medium"
        @click="trackCheapHotelSeeMore(activeCity)"
      >
        {{
          $t('hotel.checkitnow', {
            city_name: activeCity?.name ?? '',
          })
        }}
      </ApzButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.swiper-button-next,
.swiper-button-prev {
  @apply rounded-full text-gray-darkest bg-white top-[calc(235px/2-15px)] my-auto shadow-md;

  height: 48px;
  width: 48px;

  &::after {
    @apply font-bold;

    font-size: 18px;
  }
}

.swiper-button-prev {
  @apply -left-[24px];
}

.swiper-button-next {
  @apply -right-[24px];
}

.swiper-lazy-preloader {
  @apply border-x-primary border-b-primary;
}
</style>
